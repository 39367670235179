<template>
    <v-card
      v-on="$listeners"
      class="flashcard-item d-flex flex-column"
    >
      <div class="d-flex flex-grow-1">
        <div
          class="content__text d-flex flex-column justify-center flex-grow-1 pt-4"
        >
          <p class="text__speciality ma-0 flashcard__text--body">
            {{ speciality.nombre }}
          </p>
          <p class="text__area flashcard__text--subsubtitle">
            {{ speciality.area }}
          </p>
          <div
            v-if="speciality.nombre!=='Todas las especialidades'"
            class="content__rent pa-1"
            :class="{ 'content__rent--high': rent==='Alta Rentabilidad', 'content__rent--low': rent!=='Alta Rentabilidad' }"
          >
            {{ rent }}
          </div>
        </div>
        <div class="content__img d-flex justify-end align-end">
          <v-img
            v-if="!loading"
            :src="speciality.icono"
            :max-height="$vuetify.breakpoint.mobile ? '30': '48'"
            :max-width="$vuetify.breakpoint.mobile ? '30': '44'"
            class="mb-4 ml-auto mr-4"
          />
          <v-progress-circular class="ma-5" v-else indeterminate :value="20" color="primary"/>
        </div>
      </div>
      <div class="footer__line "></div>
    </v-card>
</template>

<script>
export default {
  name: 'AreasSpecialitiesItem',
  props: {
    speciality: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rent () {
      if (this.speciality.rentabilidad === 'MEDIA') {
        return 'Media Rentabilidad'
      } else if (this.speciality.rentabilidad === 'ALTA') {
        return 'Alta Rentabilidad'
      } else {
        return 'Baja Rentabilidad'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  &__text {
    width: 70%;
    padding: 0 30px;
  }
  &__img {
    flex-basis: 30%;
  }
  &__rent {
    position: absolute;
    bottom: 25px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    &--high {
      background: #12A2D6;
    }
    &--middle {
      background: #1FC1D0;
    }
    &--low {
      background: #1FC1D0;
    }
  }
}
.text {
  &__speciality {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
  }
  &__area {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: rgba(38, 38, 38, 0.5);
  }
}
.none {
  display: none !important;
}
@media (max-width: 960px) {
  .content {
    &__text {
      flex-basis: 100%;
    }
    &__rent {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
</style>
