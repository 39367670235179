<template>
  <UnitsFrame>
    <FlashcardHeader @click="$router.push({ name: 'UnitsAreas' })">
      {{ activeArea ? activeArea.nombre : "Cargando..." }}
    </FlashcardHeader>
    <AreasSpecialities/>
  </UnitsFrame>
</template>

<script>
import FlashcardHeader from '@/components/flashcard/FlashcardHeader'
import UnitsFrame from '@/components/units/UnitsFrame'
import AreasSpecialities from '@/components/units/AreasSpecialities'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UnitsAreasSpecialities',
  components: {
    UnitsFrame,
    FlashcardHeader,
    AreasSpecialities
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('flashcard', ['flashcardUnits']),
    activeArea () {
      if (this.flashcardUnits.length) {
        const currentAreaId = parseInt(this.$route.params.eid)
        return this.flashcardUnits.find(item => item.id === currentAreaId)
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions('flashcard', ['fetchFlashcardsUnits'])
  },
  created () {
    this.fetchFlashcardsUnits()
  }
}
</script>
