<template>
  <v-card
    v-bind="$attrs"
    v-on="$listeners"
    width="100%"
    class="header flashcard-item mb-5 d-inline-flex align-center justify-space-between"
  >
    <v-btn icon class="mx-5" color="#000" style="font-weight: 600;">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <p style="flex-basis: 70%" class="my-auto flashcard__text--body mr-auto">
      <slot />
    </p>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'FlashcardHeader'
}
</script>

<style>
.header {
  height: 59px !important;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px !important;
}
</style>
