<template>
  <v-container class="ma-0 pa-0">
    <v-row dense flat class="mt-2">
      <v-col
        v-for="(speciality, index) in specialitiesToShow"
        :key="index"
        class="mb-4 flashcard-col"
        :class="{'pl-4': (index + 1) % 3 === 0 && !$vuetify.breakpoint.mobile, 'px-2': (index + 1) % 3 === 2 && !$vuetify.breakpoint.mobile, 'pr-4': (index + 1) % 3 === 1 && !$vuetify.breakpoint.mobile}"
        cols="12"
        sm="6"
        md="4"
      >
        <AreasSpecialitiesItem
          :loading="loading"
          :speciality="speciality"
          @click="goToSpeciality(speciality)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AreasSpecialitiesItem from '@/components/units/AreasSpecialitiesItem'

export default {
  name: 'AreasSpecialities',
  components: {
    AreasSpecialitiesItem
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('flashcard', ['flashcardUnits', 'specialitiesByArea']),
    specialitiesWithPendingFlashcards () {
      return this.specialitiesByArea
    },
    specialitiesToShow () {
      return [{ area: ' ', icono: '', nombre: 'Todas las especialidades', id: -1 }, ...this.specialitiesWithPendingFlashcards]
    }
  },
  methods: {
    ...mapActions('flashcard', ['fetchSpecialitiesByArea']),
    goToSpeciality (item) {
      if (item.nombre === 'Todas las especialidades') {
        const specialityIds = this.specialitiesWithPendingFlashcards.map(speciality => speciality.id)
        this.$router.push({ name: 'FlashcardSimulator', params: { tipo: 'especialidades', ids: specialityIds.join(',') } })
      } else {
        this.$router.push({ name: 'UnitsAreasSpecialitiesTopics', params: { eid: this.$route.params.eid, tid: item.id } })
      }
    }
  },
  async created () {
    try {
      this.loading = true
      await this.fetchSpecialitiesByArea(this.$route.params.eid)
      this.loading = false
    } catch (e) {
      if (e.response.status === 404) {
        await this.$router.push({ name: 'Inicio' })
        return
      }
      await this.$root.$confirm({
        title: 'Ups!',
        message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
        acceptMessage: 'Ok'
      })
      this.$router.push({ name: 'Inicio' })
    }
  }
}
</script>
